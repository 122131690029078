import * as React from "react"
import { Service } from "./Service"
import webDesignIcon from "../../icons/web-design.svg"
import marketingIcon from "../../icons/audio.svg"
import brandIcon from "../../icons/marketing.svg"
import socialMediaIcon from "../../icons/network.svg"
import styled from "styled-components"

export const Title = styled.h1`
  font: 400 1.7rem/1.92rem var(--display-font);

  @media (min-width: 768px) {
  }
`

const webDesc =
  "Take advantage of one of our fully responsive, custom web design packages, or work with our skilled team of designers and developers to develop just about anything you can dream up."

const marketingDesc =
  "If you’re looking for a boost in relevant search rankings we offer SEO optimization for content and websites; while our marketing team specializes in strategic digital marketing & web campaigns."

const brandDesc =
  "Developing a brand identity is crucial to your long term success and our marketing and design professionals are here to help. We offer logo and graphic services, as well as branding and rebranding."

const socialDesc =
  "Social Media has become a major part of any successful marketing plan. Our team specializes in content creation, social media management and marketing strategies to help increase your audience and drive sales."

export const services = [
  new Service(
    "Web Design & Development",
    webDesc,
    webDesignIcon,
    (
      <>
        Web Design &<br />
        Development
      </>
    ),
    "/packages/web-design-and-development"
  ),
  new Service(
    "Marketing & SEO",
    marketingDesc,
    marketingIcon,
    (
      <>
        Digital Marketing <br />& SEO
      </>
    ),
    "/packages/digital-marketing-and-seo"
  ),
  new Service(
    "Branding & Creative",
    brandDesc,
    brandIcon,
    (
      <>
        Branding
        <br /> & Creative
      </>
    ),
    "/packages/brand-development"
  ),
  new Service(
    "Social Media",
    socialDesc,
    socialMediaIcon,
    (
      <>
        Social Media &<br /> Content Creation
      </>
    ),
    "/packages/social-media-and-content-creation"
  ),
]

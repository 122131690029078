import * as React from "react"
import styled from "styled-components"
import { services } from "../../../models/services/services"
import { ServiceItem } from "./serviceItem"

const isEven = (num: number): boolean => {
  return num % 2 === 0
}

export const Services = (): JSX.Element => {
  return (
    <>
      <Container>
        <ServiceItem service={services[0]} isLast={false} isEven={isEven(0)} />
        <ServiceItem service={services[1]} isLast={false} isEven={isEven(1)} />
        <ServiceItem service={services[2]} isLast={false} isEven={isEven(2)} />
        <ServiceItem service={services[3]} isLast={true} isEven={isEven(3)} />
      </Container>
    </>
  )
}

const Container = styled.div`
  padding: 3rem 0;
  display: grid;
  grid-template-columns: var(--grid);
  grid-column: -1/1;
  > * {
    grid-column: 2;
  }
  background-color: var(--grey);

  @media (min-width: 768px) {
    background-color: var(--white);
    padding: 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    > * {
      grid-column: initial;
    }
  }
`

import * as React from "react"
import { Head } from "../components/head"
import { Layout } from "../components/layout"
import { Intro } from "../components/_index/intro"
import SEO from "../components/seo"
import { CallToAction } from "../components/_index/callToAction"
import { Slogan } from "../components/_index/slogan"
import { Services } from "../components/_index/services/services"

const IndexPage = (): JSX.Element => {
  return (
    <>
      <SEO title="Home" />
      {/* <Head title={"Who Studio"} /> */}
      <Layout>
        <Intro />
        <CallToAction />
        <Slogan />
        <Services />
      </Layout>
    </>
  )
}
export default IndexPage

import * as React from "react"
import styled from "styled-components"
import { HeaderCenter, SubHeaderCenter } from "../shared/type"
import { SectionContainer } from "../shared/containers"

export const Slogan = (): JSX.Element => {
  return (
    <>
      <SectionContainer>
        <HeaderCenter>
          Not just a web <SBR />
          design company.
        </HeaderCenter>
        <SubHeaderCenter style={{ fontSize: "1.25rem", lineHeight: "1.5rem" }}>
          Your own personal web &<SBR /> marketing agency,
          <LBR /> for the
          <SBR /> price of a phone bill.
        </SubHeaderCenter>
      </SectionContainer>
    </>
  )
}

const Statement = styled.h2`
  text-align: center;
  font: 400 1rem/1.5rem var(--body-font);

  @media (min-width: 768px) {
    font: 400 1.2rem/1.7rem var(--body-font);
  }
`

const SBR = styled.br`
  @media (min-width: 768px) {
    display: none;
  }
`

const LBR = styled.br`
  display: none;

  @media (min-width: 768px) {
    display: initial;
  }
`

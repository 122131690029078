import * as React from "react"
import styled from "styled-components"
import { Service } from "../../../models/services/Service"
import { Link } from "gatsby"
import { SubHeader } from "../../shared/type"
import { SectionContainer, BannerContainer } from "../../shared/containers"

interface Props {
  service: Service
  isLast: boolean
  isEven: boolean
}

export const ServiceItem: React.FC<Props> = (props: Props) => {
  const { service, isLast, isEven } = props

  const FlexedLink = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: ${isEven ? "row" : "row-reverse"};
    text-align: center;

    @media (min-width: 768px) {
      flex-direction: column;
    }
  `
  return (
    <Container>
      <FlexedLink to={service.link}>
        <SubHeader>{service.jsx}</SubHeader>
        <Icon src={service.icon} alt={service.name}></Icon>
        <Description>{service.description}</Description>
      </FlexedLink>
      {!isLast && <HR />}
    </Container>
  )
}

const Container = styled.section`
    width:100%;
    max-width:300px;
    margin: 0 auto;
  @media (min-width: 768px) {
    border: var(--background) 2px solid;
    border-width: 2px 1px 2px 1px;
    padding: 5rem 2rem 5rem 2rem;
    transition-duration: 0.3s;

    :hover {
      img {
        filter: invert(0.4) sepia(1) hue-rotate(80deg) brightness(90%)
          contrast(150%);
      }

      transform: translateY(-8px);
      cursor: pointer;
      box-shadow: var(--shadow);
    }
  }
`

const Description = styled.p`
  display: none;

  @media (min-width: 768px) {
    display: initial;
    text-align: left;
  }
`

const HR = styled.hr`
  margin: 1.5rem 0;

  @media (min-width: 768px) {
    display: none;
  }
`

const Icon = styled.img`
  height: 50px;
  width: auto;

  @media (min-width: 768px) {
    height: 100px;
    order: -1;
    margin-bottom: 2.25rem;
  }
`
import * as React from "react"
import styled from "styled-components"
import { Button } from "../../atoms/button"
import { Link } from "gatsby"
import { SectionContainer } from "../shared/containers"
import { Header } from "../shared/type"

export const CallToAction = () => {
  return (
    <Container>
      <Tagline>Here for hire, here for help.</Tagline>
      <PriceContainer>
        <Price>
          $148 <span>/mo</span>
        </Price>
        <HorizontalRule />
        <Sub>
          price includes custom website build and
          <br /> hosting
        </Sub>
      </PriceContainer>
      <VerticalRule />
      <ServicesContainer>
        <Link to="/packages">
          <CTAButton role="button">get started</CTAButton>
        </Link>
        <Services>
          <ServiceItem>
            <b style={{color: "var(--brand-green)"}}>✓ </b> Custom domain
          </ServiceItem>
          <ServiceItem>
            <b style={{color: "var(--brand-green)"}}>✓ </b> Email setup
          </ServiceItem>
          <ServiceItem>
            <b style={{color: "var(--brand-green)"}}>✓ </b> Website hosting
          </ServiceItem>
          <ServiceItem>
            <b style={{color: "var(--brand-green)"}}>✓ </b> Optimized for performance
          </ServiceItem>
          <ServiceItem>
            <b style={{color: "var(--brand-green)"}}>✓ </b> Tailored to your needs
          </ServiceItem>
          <ServiceItem>
            <b style={{color: "var(--brand-green)"}}>✓ </b> Superior SEO
          </ServiceItem>
        </Services>
      </ServicesContainer>
    </Container>
  )
}

const Container = styled(SectionContainer)`
  display: grid;
  grid-template-columns: var(--grid);
  grid-column: -1/1;
  > * {
    grid-column: 2;
  }

  background-color: #e8e8e8;

  @media (min-width: 768px) {
    grid-column-gap: 75px;
    align-items: center;
    justify-content: center;
    grid-template-columns: 1fr 2px 1fr;
    > * {
      grid-column: initial;
    }
  }
`

const CTAButton = styled(Button)`
  width: 100%;
  order: 1;

  @media (min-width: 768px) {
    margin: 0;
    width: 270px;
    height: 60px;
    font-size: 1.2rem;
  }
`

const Price = styled.h2`
  font: 2rem/2rem var(--numeric);
  margin-bottom: 0.2rem;

  > span {
    font-size: 1rem;
  }

  @media (min-width: 768px) {
    font-size: 5rem;

    > span {
      font-size: 1.3rem;
    }
  }
`

const PriceContainer = styled.div`
  margin-bottom: 1.5rem;

  @media (min-width: 768px) {
    margin: 0 2rem 0 25px;
    display: grid;
    justify-content: end;
  }
`

const ServicesContainer = styled.div`
  @media (min-width: 768px) {
    display: grid;
    justify-content: start;
  }
`

const ServiceItem = styled.li`
  color: var(--black);
  font: 0.9rem/1.2rem var(--body-font);
`

const HorizontalRule = styled.hr`
  margin-bottom: 0.4rem;

  @media (min-width: 768px) {
    display: none;
  }
`

const VerticalRule = styled.hr`
  display: none;

  @media (min-width: 768px) {
    display: initial;
    width: 100%;
    height: 40%;
  }
`

const Services = styled.ul`
  list-style-type: none;
  padding-top: 1.5rem;

  @media (min-width: 768px) {
    margin-bottom: 2rem;
  }
`

const Sub = styled.sub`
  color: var(--brand-green);
  font: 0.9rem/1.2rem var(--body-font);

  @media (min-width: 768px) {
    font-size: 1rem;
  }
`

const Tagline = styled(Header)`
  font: 400 1.5rem/2rem var(--display-font);
  text-align: center;

  @media (min-width: 768px) {
    display: none;
  }
`

import * as React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"
import { Header } from "../shared/type"

export const Intro = (): JSX.Element => {
  const { staffMobileImage } = useStaticQuery(
    graphql`
      query {
        staffMobileImage: file(relativePath: { eq: "index/main.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  const staffFluid = staffMobileImage.childImageSharp.fluid
  return (
    <>
      <Container>
        <Headings>
          <Statement>
            Affordable digital solutions
            <br />
            for everyone.
          </Statement>
          <Tagline>Here for hire, here for help.</Tagline>
        </Headings>
        <FullBleed>
          <Img fluid={staffFluid} />
        </FullBleed>
      </Container>
    </>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: var(--grid);
  > * {
    grid-column: 2;
  }

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;

    > * {
      grid-column: auto;
    }
  }
`
const FullBleed = styled.div`
  grid-column: -1/1;

  @media (min-width: 768px) {
    grid-column: 2;
    margin-top: 125px;
  }
`
const Statement = styled(Header)`
  text-align: left;
  font: 400 1.45rem/1.9rem var(--display-font);
  margin: var(--padding-top-mobile) 0 var(--padding-top-mobile) 0;

  @media (min-width: 600px) {
    font: 400 2.25rem/2.5rem var(--display-font);
  }
  @media (min-width: 768px) {
    font: 400 2.5rem/3rem var(--display-font);
    margin-bottom: var(--margin-bottom-desktop);
  }
`

const Tagline = styled(Header)`
  display: none;
  @media (min-width: 768px) {
    display: initial;
  }
`
const Headings = styled.div`
  @media (min-width: 768px) {
    padding-left: 25px;
    padding-right: 25px;
    display: grid;
    align-content: center;
    justify-content: center;
  }
`
